export enum AppearanceTheme {
  SOFTWARE = 'software',
  ACADAEMY = 'academy',
  SERVICES = 'services',
}

export interface CurrentTheme {
  name: string
  color: 'default' | `${AppearanceTheme}`
  gradient: string
}

interface CmsPageProvider {
  currentTheme: ComputedRef<CurrentTheme>
}

export const CmsPageProviderKey: InjectionKey<CmsPageProvider>
  = Symbol('cmsPage')
